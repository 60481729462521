// Autocomplete.js
import React, { useState, useEffect, useRef } from 'react';
import UserSection from '../../Services/User/UserLogin';
const Autocomplete = ({ suggestions=[], onSelected }) => {
    const [inputValue, setInputValue] = useState('');
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const inputRef = useRef(null);

    // useEffect(() => {
    //     setFilteredSuggestions(suggestions.filter(suggestion =>
    //         suggestion.toLowerCase().includes(inputValue.toLowerCase())
    //     ));
    // }, [inputValue, suggestions]);

    const handleClickOutside = (event) => {
        if (inputRef.current && !inputRef.current.contains(event.target)) {
            setShowSuggestions(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        setShowSuggestions(true);
    };

    const handleSuggestionClick = (suggestion) => {
        
        setInputValue(suggestion.product_desc);
        setShowSuggestions(false);
        onSelected(suggestion);
    };
    const handleKeyUp = async (e) => {
        try {
            // setName(e.target.value);
            const response = await UserSection.byProduct(e.target.value);
            setFilteredSuggestions(response.data);
            
        } catch (error) {

        }

    }
    return (
        <div ref={inputRef}>
              <div className=" mb-4 search_box">
            <input
                type="search"
                value={inputValue}
                onKeyUp={handleKeyUp}
                onChange={handleInputChange}
                class="form-control w-full "
                placeholder='Smart Search'
                style={{ textAlign: "center" }}
                className="flex-1 form-text rounded text-black-500 text-center"
            />
            {showSuggestions && (
              <ul style={{ background: '#ddcfcf' }}>
                    {filteredSuggestions.map((suggestion, index) => (
                        <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                            {suggestion.product_desc} /{suggestion.sku_code}
                        </li>
                    ))}
                </ul>
            )}
            </div>
        </div>
    );
};

export default Autocomplete;
